import React, { Component } from 'react';
import coins_icon from '../../images/coins.png';
import amber_roi_mobile from '../../images/amber_roi_mobile.png';
import axios from '../../helper/Axios';
import { NotificationManager } from 'react-notifications';
import classnames from 'classnames';

export default class RoiResult extends Component {

    state = {
        email: '',
        phone: '',
        loading: false,
        blur_result: true
    }

    onChange = (e) => {
        const value = (e.target.value ? e.target.value  : '');
        if(e.target.name === 'email'){
            this.setState({email: value});
        } else {
            this.setState({phone: value});
        }
    }

    getCookie = (name) => {
        let re = new RegExp(name + "=([^;]+)");
        let value = re.exec(document.cookie);
        return (value != null) ? unescape(value[1]) : null;
    }
        
    submitROI = async (e) => {
        e.preventDefault();
        const { emp_headcount, average_hrbp_salary, hrbp_emp_ratio, average_emp_salary, workforce_leave, roi} = this.props;
        const { email, phone } = this.state;
        this.setState({loading: true});
        const path = window.location.pathname;
        const page_name = path.split("/").pop();
        const page_uri = window.location.href;
        const hutk = this.getCookie('hubspotutk');
        const response = await axios.post('/hubspot/roi_calculator_form', {
            roi_calc_employee_headcount: emp_headcount, 
            roi_calc_hrbp_salary: average_hrbp_salary, 
            roi_calc_hrbp_employee_ratio: hrbp_emp_ratio, 
            roi_calc_employee_salary: average_emp_salary, 
            roi_calc_employee_churn: workforce_leave, 
            roi_calc_total_savings: roi,
            email, 
            phone,
            page_uri, 
            hutk, 
            page_name
        });
        if(response && response.status === 200){
            NotificationManager.success("Thank you, We will get back to you shortly!");
            this.setState({phone: '', email: ''});
            this.setState({blur_result : false});
        }
        this.setState({loading: false})
    }

    render() {
        const { roi } = this.props;
        const { phone, email, loading, blur_result } = this.state;
        return (
            <div className="bg-amber-roi">
                <div className="columns mg-top-10">
                    <div className="column is-3 is-offset-3">
                        <div className="has-text-centered is-hidden-tablet">
                            <img src={amber_roi_mobile} alt="amber" width="200px"/>
                        </div>
                        {roi>0?
                        <div className="card roi-card-2">
                            <div>
                                <span className= "roi-cost">$</span>
                                <span  className={classnames('roi-cost', {'roi-blur-result': blur_result})}>{blur_result ? '99,999,999' : parseFloat(roi).toLocaleString('en-US')}</span>
                                <img className="roi-coins" src={coins_icon} alt="coins" width="40px"/>
                            </div>
                            <div className="roi-text">
                                <h3>This is what you can save by bringing me onto your team!</h3>
                                <p>calculated based on HRBP time saved, attrition reduction, and disengagement costs saved</p>
                            </div>
                        </div>
                        :<div className="roi-text">
                            <h3>Amber might not be the right fit for your organization right now, but stay tuned!</h3>
                        </div>}
                    </div>
                    <form onSubmit={this.submitROI} className="column is-offset-1 is-4 roi-form">
                        <h3>Ready to be surprised? Submit the details below to view the result</h3>
                        <br/>
                        <div className="field has-addons columns flex-wrap">
                            <div className="control column is-12">
                                <input className="input min-width-250" type="text" required value={email} onChange={this.onChange} name="email" placeholder="Business Email"/>
                            </div>
                            <br/>
                            <div className="control column is-12">
                                <input className="input min-width-250" type="text" required value={phone} onChange={this.onChange} name="phone" placeholder="Phone Number"/>
                            </div>
                            <br/>
                            <div className="control column is-12">
                                &nbsp;
                                <button className={`button border-radius-5 is-link ${loading ? 'is-loading' : ''}`}>
                                    View Result
                                </button>
                            </div>
                        </div>
                        <br/>
                        <br/>
                    </form>
                </div>
            </div>
        )
    }
}
