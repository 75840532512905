import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RoiCalculator from "../components/roicalculator/RoiCalculator";

const RoiCalc = () => (
  <Layout>
    <SEO title="Amber by inFeedo | ROI" description="Amber is an AI chatbot that talks to your employees to helps you measure employee experience in your organization. As Amber travels the world, catch all the action here. Be a part of our ever-growing community."/>
    <RoiCalculator/>
  </Layout>
)

export default RoiCalc;